import { useMemo } from 'react'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { mgrClientMarketingPreferencesMessages } from '../../../locales'

export const useMarketingPreferencesFormSchema = () => {
  const { formatMessage } = useLocales()
  const bothWaysMessage = formatMessage(mgrClientMarketingPreferencesMessages.errorOptAllBothWays)
  return useMemo(
    () =>
      z
        .object({
          optOutAllSms: z.boolean(),
          optOutAllEmail: z.boolean(),
          optInAllEmail: z.boolean(),
          emailVenues: z.record(z.string().min(1), z.boolean().optional()),
          smsVenues: z.record(z.string().min(1), z.boolean().optional()),
          tailoredCommunicationOptIn: z.boolean(),
        })
        .superRefine((formData, ctx) => {
          if (formData.optOutAllEmail && formData.optInAllEmail) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: bothWaysMessage,
            })
          }
        }),
    [bothWaysMessage]
  )
}
export type MarketingPreferencesForm = z.infer<ReturnType<typeof useMarketingPreferencesFormSchema>>
