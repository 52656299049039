import { ClientMarketingPreferenceOptInStatus } from '@sevenrooms/core/api'
import { type Field, useWatch, useWatchMany } from '@sevenrooms/core/form'
import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { mgrClientMarketingPreferencesMessages } from '../../../locales'
import { MarketingPreferenceTableRow } from '../Table/MarketingPreferenceTableRow'
import type { MarketingPreferencesForm } from './MarketingPreferences.zod'

interface StatusDisplayProps {
  optInStatus: ClientMarketingPreferenceOptInStatus
  unsubscribedLabel: InternationalizationText
  testLabel: string
  channelUnavailable: boolean
  disabled: boolean
  globalOverrideIn?: boolean
  globalOverrideOut?: boolean
  updatedState?: boolean
  field?: Field<boolean | undefined>
}
function StatusDisplay({
  optInStatus,
  unsubscribedLabel,
  testLabel,
  channelUnavailable,
  disabled,
  globalOverrideIn,
  globalOverrideOut,
  updatedState,
  field,
}: StatusDisplayProps) {
  const { formatMessage } = useLocales()
  if (channelUnavailable) {
    return <>{formatMessage(mgrClientMarketingPreferencesMessages.optInStatusChannelUnavailable)}</>
  }
  if (optInStatus === ClientMarketingPreferenceOptInStatus.UNSUBSCRIBED || updatedState === false || globalOverrideOut) {
    if (field) {
      field.set(false, { shouldDirty: false, shouldValidate: false, shouldTouch: false })
    }
    return <>{formatMessage(unsubscribedLabel)}</>
  }
  return (
    <Checkbox
      data-test={`mkt-pref-check-${testLabel}`}
      checked={optInStatus === ClientMarketingPreferenceOptInStatus.SUBSCRIBED || (globalOverrideIn && !disabled)}
      field={field}
      key={field?.name}
      disabled={disabled}
    />
  )
}

interface VenuePreferenceRowProps {
  field: Field<MarketingPreferencesForm>
  venueId: string
  venueName: string
  smsOptInStatus: ClientMarketingPreferenceOptInStatus
  emailOptInStatus: ClientMarketingPreferenceOptInStatus
  smsEnabled: boolean
  emailUsesDoubleOptIn: boolean
  emphasizeRow: boolean
  isLastRow?: boolean
  isFirstRow?: boolean
}

export function VenuePreferenceRow({
  field,
  isLastRow,
  isFirstRow,
  emphasizeRow,
  venueId,
  venueName,
  smsOptInStatus,
  emailOptInStatus,
  smsEnabled,
  emailUsesDoubleOptIn,
}: VenuePreferenceRowProps) {
  const emailField = field.prop(`emailVenues.${venueId}`)
  const smsField = field.prop(`smsVenues.${venueId}`)
  const updatedEmailState = useWatch(emailField)
  const updatedSMSState = useWatch(smsField)
  const [globalOverrideSMSOptOut, globalOverrideEmailOptOut, globalOverrideEmailOptIn] = useWatchMany(field, [
    'optOutAllSms',
    'optOutAllEmail',
    'optInAllEmail',
  ])

  return (
    <MarketingPreferenceTableRow isLastRow={isLastRow} isFirstRow={isFirstRow} testId={`venue-${venueId}`} key={venueId}>
      {[
        <VStack key={`mkt-pref-row-label-${venueId}`} pt="xs" pb="xs">
          <Text fontWeight={emphasizeRow ? 'bold' : 'normal'} key={venueName}>
            {venueName}
          </Text>
        </VStack>,

        <StatusDisplay
          optInStatus={emailOptInStatus}
          unsubscribedLabel={mgrClientMarketingPreferencesMessages.optInStatusUnsubscribed}
          testLabel="email"
          channelUnavailable={false}
          disabled={emailUsesDoubleOptIn && emailOptInStatus !== ClientMarketingPreferenceOptInStatus.SUBSCRIBED}
          globalOverrideIn={globalOverrideEmailOptIn}
          globalOverrideOut={globalOverrideEmailOptOut}
          updatedState={updatedEmailState}
          field={emailField}
          key="email"
        />,
        <StatusDisplay
          optInStatus={smsOptInStatus}
          unsubscribedLabel={mgrClientMarketingPreferencesMessages.optInStatusNotSubscribed}
          testLabel="sms"
          channelUnavailable={!smsEnabled}
          disabled={false}
          globalOverrideIn={false}
          globalOverrideOut={globalOverrideSMSOptOut}
          updatedState={updatedSMSState}
          field={smsField}
          key="sms"
        />,
      ]}
    </MarketingPreferenceTableRow>
  )
}
