import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { mgrClientMarketingPreferencesMessages } from '../../../locales'
import type { MarketingPreferencesForm } from './MarketingPreferences.zod'

interface TailoredCommunicationOptInProps {
  field: Field<MarketingPreferencesForm>
  optedIn: boolean
}

export function TailoredCommunicationOptIn({ field, optedIn }: TailoredCommunicationOptInProps) {
  const { formatMessage } = useLocales()
  const tailoredCommunicationOptInField = field.prop('tailoredCommunicationOptIn')

  return (
    <Checkbox
      data-test="mkt-pref-check-tailored-communication"
      field={tailoredCommunicationOptInField}
      checked={optedIn}
      key={tailoredCommunicationOptInField?.name}
    >
      {formatMessage(mgrClientMarketingPreferencesMessages.marketingPrefsTailoredCommunicationOptIn)}
    </Checkbox>
  )
}
